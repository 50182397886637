<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
                :tool-list="['keyword', 'gradeName', 'term']" label-width="55px">
                <el-form-item label='周次'>
                    <el-select size="small" v-model="queryInfo.weekNo" placeholder="请选择">
                        <el-option v-for="(item, index) in  25 " :key="index" :label="`第${item}周`" :value="item"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="部门" v-permission="['infoCollect:week:all']">
                    <el-select v-model="queryInfo.deptId" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
            </view-search-form>
            <div class="header-button mb-1">
                <template v-if="batchCheckFlag">
                    <el-button size="small" type="success" @click="handleBathCheck('pass')">批量通过</el-button>
                    <el-button size="small" type="danger" plain @click="handleBathCheck('reject')">批量驳回</el-button>
                </template>
                <el-button size="small" :type="batchCheckFlag ? '' : 'primary'" @click="handleBathCheck()">
                    {{ batchCheckFlag ? '关闭' : '开启' }}批量审批
                </el-button>
                <!-- <el-button @click="showDialog()" type="success" size="small">
                    每周信息采集导出
                </el-button> -->
            </div>
        </template>
        <el-table ref="tableRef" @selection-change="handleSelectChange" height="72vh" :data="tableData" class=" mt-1"
            style="width: 100%" border stripe>
            <el-table-column type="selection" width="50" :selectable="handleRowSelectable"/>
            <el-table-column type="index" label="ID"/>
            <el-table-column prop="realName" label="姓名"/>
            <el-table-column prop="gradeName" label="学年"/>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term} 学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="weekNo" label="周次">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.weekNo} 周` : '' }}
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="deptName" label="部门"/>
            <el-table-column show-overflow-tooltip prop="auditStatus" label="审核情况">
                <template v-slot="{ row }">
                    <el-tag v-if="row.auditStatus === 3" type="success">审核通过</el-tag>
                    <el-tag v-else-if="row.auditStatus === 2" type="danger">审核不通过</el-tag>
                    <el-tag v-else-if="row.auditStatus === 1">未审核</el-tag>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="auditMessage" label="审核反馈"/>
            <el-table-column show-overflow-tooltip prop="mainWorkContents" label="本周主要工作内容"/>
            <el-table-column show-overflow-tooltip prop="selfDiagnosisOpinion" label="自我诊断意见"/>
            <el-table-column show-overflow-tooltip prop="improvement" label="改进建议"/>
            <el-table-column prop="type" label="类型"/>
            <el-table-column label="操作" width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <dialog-add-weekly-report :isOnlyRead="true" ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog'
            @on-close='renderTable' />
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'

import { delWeeklyReportByUserId, pageWeeklyReport, batchApproveWeeklyReport } from '@/api/infoCollect/weeklyInfoCollect-api.js'
import DialogAddWeeklyReport from './components/DialogAddWeeklyReport.vue'
import store from '@/store/index'

export default {
    components: { DialogAddWeeklyReport },
    mixins: [tableView],
    data() {
        return {
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                currentWorkSummary: '',
                currentSignificantEvents: '',
                nextWorkPlan: '',
                selfDiagnosisOpinions: '',
                improvement: ''
            },
            queryInfo: {
                gradeName: null,
                term: null,
                deptId: null,
                weekNo: null
            },
            batchCheckFlag: false, // 是否开启批量审批
            departmentList: '',
            rowCheckList: [] // table 选中的
        }
    },
    mounted() {
        this.initDate()
        this.renderTable()
        this.getDepartment()
    },
    methods: {
        delWeeklyReportByUserId,
        async renderTable(pageNum) {
            this.getAll()

            await this.getTableData(pageWeeklyReport, pageNum)
        },
        // 批量审核
        handleBathCheck(flag) {
            if (!flag) {
                this.batchCheckFlag = !this.batchCheckFlag
                this.$refs.tableRef.clearSelection()
                this.rowCheckList = []
                return
            }

            let _msg
            let _status = 2
            let _type = 'error'
            if (flag === 'pass') {
                _msg = '批量通过'
                _status = 3
                _type = 'success'
            } else {
                _msg = '批量驳回'
            }

            this.$confirm(`是否确认${_msg}选中的${this.rowCheckList.length}份每周信息采集？`, '批量审批').then(() => {
                this.loading = true
                const _formData = {
                    ids: this.rowCheckList,
                    auditStatus: _status
                }

                batchApproveWeeklyReport(_formData).then(() => {
                    this.$message({
                        message: `已${_msg}!`,
                        type: _type
                    })
                    this.renderTable()
                    this.loading = false
                })
            })
        },
        // table 多选控制,获取选中的id
        handleSelectChange(val) {
            while (this.rowCheckList.length) {
                this.rowCheckList.pop()
            }
            if (val.length) {
                val.forEach(({ id }) => {
                    this.rowCheckList.push(id)
                })
            }
        },
        // table checkbox控制,只能选择待审批的单子
        handleRowSelectable(row) {
            return Number(row.auditStatus) === 1
        },
        // 查看全部的权限
        getAll() {
            const _permission = store.getters['login/getPermission']

            if (!(_permission.includes('infoCollect:week:all'))) {
                this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
            }
        },
        // 获取部门
        getDepartment() {
            this.$http.getDepartmentListQuery({}).then((res) => {
                this.departmentList = res.data
            })
        },
        // 获取当年学期、学年
        initDate() {
            const time = new Date()
            const year = time.getFullYear()
            const month = time.getMonth() + 1

            // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
            if (month >= 3 && month < 9) {
                this.queryInfo.term = '2'
                this.queryInfo.gradeName = `${year - 1}-${year}`
            } else if (month >= 9) {
                this.queryInfo.term = '1'
                this.queryInfo.gradeName = `${year}-${year + 1}`
            } else {
                this.queryInfo.term = '1'
                this.queryInfo.gradeName = `${year - 1}-${year}`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
