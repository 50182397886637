import request from '@/service/request'

/* 每周信息采集 获取接口
* id
* */
export const getWeeklyReportByUserId = (id) => {
    return request.post(`/teacher/teacherWeeklyReport/getById/${id}`)
}
/* 每周信息采集 删除接口
* id
* */
export const delWeeklyReportByUserId = (id) => {
    return request.post(`/teacher/teacherWeeklyReport/del/${id}`)
}
/* 每周信息采集 审核接口（批量操作）
* */
export const batchApproveWeeklyReport = (params) => {
    return request.post('/teacher/teacherWeeklyReport/batchApprove', params)
}

/* 每周信息采集 保存接口（当前用户）
* */
export const saveWeeklyReportByCurUser = (params) => {
    return request.post('/teacher/teacherWeeklyReport/saveCurrentUser', params)
}
/* 每周信息采集 保存接口
* */
export const saveWeeklyReport = (params) => {
    return request.post('/teacher/teacherWeeklyReport/save', params)
}

/* 每周信息采集 page接口（当前用户）
* */
export const pageWeeklyReportByCurUser = (params) => {
    return request.post('/teacher/teacherWeeklyReport/pageCurrentUser', params)
}

/* 每周信息采集 page接口
* */
export const pageWeeklyReport = (params) => {
    return request.post('/teacher/teacherWeeklyReport/page', params)
}
