<template>
  <el-dialog class="grade-dialog" :title="`${preTitle}每周信息采集`" :visible.sync="show" :close-on-click-modal="false"
    width="1000px" :before-close="cancel">
    <el-form class="" ref="form" :model="formData" :rules="rules" label-width="150px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="学年" prop="gradeName">
            <el-select :disabled="isOnlyRead" v-model="formData.gradeName" placeholder="请选择">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.gradeName" :value="item.gradeName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学期" prop="term">
            <el-select :disabled="isOnlyRead" v-model="formData.term" placeholder="请选择">
              <el-option v-for="item in termList" :key="item.id" :label="`第${item.paramValue}学期`"
                :value="item.paramValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类型" prop="type">
            <el-select :disabled="isOnlyRead" v-model="formData.type" placeholder="请选择">
              <el-option v-for="(item, index)  in typeList" :key="index" :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="周次" prop="weekNo">
            <el-input-number :disabled="isOnlyRead" v-model="formData.weekNo" :min="1" :max="30" />
          </el-form-item>
        </el-col>

      </el-row>
      <el-col :span="24">
        <el-form-item label="本周主要工作内容" prop="mainWorkContents">
          <el-input maxlength="3000" :disabled="isOnlyRead" v-model="formData.mainWorkContents" type="textarea"
            rows="3"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="自我诊断意见" prop="selfDiagnosisOpinion">
          <el-input maxlength="3000" :disabled="isOnlyRead" v-model="formData.selfDiagnosisOpinion" type="textarea"
            rows="3"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="改进建议" prop="improvement">
          <el-input maxlength="3000" :disabled="isOnlyRead" v-model="formData.improvement" type="textarea"
            rows="3"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" v-if="!addFlag">
        <el-form-item label="审核反馈" prop="auditMessage">
          <el-input maxlength="3000" :disabled="!isOnlyRead || formData.auditStatus !== 1" v-model="formData.auditMessage"
            type="textarea" rows="3"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <template #footer>
      <cancel-popover v-if="!isOnlyRead" ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button v-if="!isOnlyRead" type="primary" @click="save('每周信息采集', saveWeeklyReportByCurUser)">确 定
      </el-button>
      <template v-if="isOnlyRead && formData.auditStatus === 1">
        <el-button type="success" @click="checkWeekly(3)" plain>审核通过</el-button>
        <el-button type="danger" @click="checkWeekly(2)" plain>审核不通过</el-button>
      </template>

    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

import { getGradeList } from '@/api/grade.js'
import { getParamsList } from '@/api/sysParams.js'
import {
  saveWeeklyReportByCurUser,
  saveWeeklyReport,
  getWeeklyReportByUserId
} from '@/api/infoCollect/weeklyInfoCollect-api.js'

import { typeList } from './options'

export default {
  name: 'gradeDialog',
  mixins: [dialog],
  data() {
    return {
      id: '',
      addFlag: false,
      formData: {
        id: '',
        userId: '',
        realName: '',
        auditStatus: null,
        deptName: '',
        teacherNo: '',
        gradeName: '',
        term: '',
        weekNo: '',
        mainWorkContents: '',
        selfDiagnosisOpinion: '',
        auditMessage: '',
        improvement: '',
        type: '日常工作'
      },
      gradeList: '',
      termList: '',
      typeList,
      rules: {
        gradeName: [{
          required: true,
          message: '请选择学年'
        }],
        term: [{
          required: true,
          message: '请选择学期'
        }],
        weekNo: [{
          required: true,
          message: '请填写周次'
        }]
      }
    }
  },
  props: {
    isOnlyRead: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initDate()
      this.getGradeList()
      this.getTermList()
      if (this.id) {
        this.getDataById(this.id, getWeeklyReportByUserId)
      } else {
        this.addFlag = true
      }

      if (this.isOnlyRead) {
        this.preTitle = '查看'
      }
    })
  },
  methods: {
    saveWeeklyReportByCurUser, // 保存 api
    saveWeeklyReport,
    // 获取学年
    getGradeList() {
      getGradeList({}).then((res) => {
        this.gradeList = res.data.list
      })
    },
    //  获取学期
    getTermList() {
      getParamsList({ keyword: 'term' }).then((res) => {
        this.termList = res.data.list
      })
    },
    // 审核
    checkWeekly(auditStatus) {
      this.formData.auditStatus = auditStatus
      this.preTitle = ''
      this.save('每周信息采集审核情况', saveWeeklyReport)
    },
    initDate() {
      const time = new Date()
      const year = time.getFullYear()
      const month = time.getMonth() + 1

      // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
      if (month >= 3 && month < 9) {
        this.formData.term = '2'
        this.formData.gradeName = `${year - 1}-${year}`
      } else if (month >= 9) {
        this.formData.term = '1'
        this.formData.gradeName = `${year}-${year + 1}`
      } else {
        this.formData.term = '1'
        this.formData.gradeName = `${year - 1}-${year}`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-textarea,
.el-input,
.el-select {
  &.is-disabled {

    .el-textarea__inner,
    .el-input__inner {

      background-color: #fff !important;
      color: #606266 !important;
    }
  }
}
</style>
